/*
# Copyright 2022, Google, Inc.
# Licensed under the Apache License, Version 2.0 (the "License");
# you may not use this file except in compliance with the License.
# You may obtain a copy of the License at
#
#    http://www.apache.org/licenses/LICENSE-2.0
#
# Unless required by applicable law or agreed to in writing, software
# distributed under the License is distributed on an "AS IS" BASIS,
# WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
# See the License for the specific language governing permissions and
# limitations under the License.
*/
/*
https://www.npmjs.com/package/react-ace
https://ace.c9.io/
*/
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import GCS from "./gcs";
import { Box, Button, Paper } from "@material-ui/core";
import ReactResizeDetector from "react-resize-detector";

import EditorJSON from "./components/editors/EditorJSON";
import EditorText from "./components/editors/EditorText";
import EditorJSONTree from "./components/editors/EditorJSONTree";
import { enqueueSnackbar } from "notistack";

function FileEditor(props) {
  const jsonTreeRef = useRef(null);
  const jsonRef = useRef(null);
  const textRef = useRef(null);
  // props.selection {
  //   bucket: bucketName
  //   selection: set of strings
  // }

  const [text, setText] = useState("");
  const [json, setJson] = useState({});
  const [textMode, setTextMode] = useState("text");

  const changeTextMode = (mode) => {
    setTextMode(mode);
    try {
      if (mode === "text") {
        setText(JSON.stringify(json));
      }
      if (mode.startsWith("json") && textMode === "text") {
        setJson(JSON.parse(text));
      }
    } catch (error) {
      setJson({ text: "error, not valid json" });
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    async function run() {
      //GCS.getData(bucket, selectedFile.id)
      // If we have no, selection, nothing to do.
      if (props.selection === null) {
        return;
      }
      // If we have more than 1 selection, nothing to do
      if (props.selection.selection.size !== 1) {
        return;
      }
      const fileName = props.selection.selection.values().next().value;

      //debugger;
      // Get the metadata of the object
      const metaData = await GCS.getMetaData(props.selection.bucket, fileName);

      // If the size of the object is too big, don't load
      const size = Number(metaData.size);
      if (size > 1024 * 1024 * 5) {
        console.log("File too big");
        setJson(JSON.stringify(["<TOO BIG>"]));
        setText("<TOO BIG>");
        return;
      }

      // Load the object
      console.log(`Reading file ${props.selection.bucket} - ${fileName}`);
      // We now know the file to load in the editor.  Let us now load the data
      const content = await GCS.getData(props.selection.bucket, fileName);
      setText(content);
      try {
        setJson(JSON.parse(content));
      } catch (error) {
        setJson({ text: "error, not valid json" });
        console.log("error: ", error);
      }
      if (textRef?.current?.jsonEditor && textMode === "text") {
        textRef.current.jsonEditor.set(content);
      }
      if (jsonRef?.current?.jsonEditor && textMode === "json") {
        try {
          jsonRef.current.jsonEditor.set(JSON.parse(content));
        } catch (error) {
          jsonRef.current.jsonEditor.set({ text: "error, not valid json" });
          console.log("error: ", error);
        }
      }
      if (jsonTreeRef?.current?.jsonEditor && textMode === "json-tree") {
        try {
          jsonTreeRef.current.jsonEditor.set(JSON.parse(content));
        } catch (error) {
          jsonTreeRef.current.jsonEditor.set({ text: "error, not valid json" });
          console.log("error: ", error);
        }
      }
    } // End of run
    run();
  }, [props.selection.bucket, props.selection.selection]);

  function onChange(newValue) {
    //console.log("Changed!")
    if (textMode === "text") {
      setText(newValue);
    }
    if (textMode.startsWith("json")) {
      setJson(newValue);
    }
  } // End of onChange

  async function onSave() {
    try {
      if (
        textMode === "json" &&
        jsonRef?.current?.jsonEditor?.lastSchemaErrors &&
        jsonRef.current.jsonEditor.lastSchemaErrors.length > 0
      ) {
        throw new Error("not valid json");
      }
      if (
        textMode === "json-tree" &&
        jsonTreeRef?.current?.jsonEditor?.lastSchemaErrors &&
        jsonTreeRef.current.jsonEditor.lastSchemaErrors.length > 0
      ) {
        throw new Error("not valid json");
      }

      if (props.selection === null) {
        throw new Error("no props.selection");
      }
      // If we have more than 1 selection, nothing to do
      if (props.selection.selection.size !== 1) {
        console.log(
          `Error: props.selection = ${props.selection.selection.size}`
        );
        throw new Error("more than one props.selection");
      }
      console.log(`Saving ${text}`);
      const fileName = props.selection.selection.values().next().value;
      if (textMode === "text") {
        await GCS.write(props.selection.bucket, fileName, text);
      }
      if (textMode.startsWith("json")) {
        await GCS.write(props.selection.bucket, fileName, JSON.stringify(json));
      }
      enqueueSnackbar({ message: "Successfully saved!", variant: "success" });
    } catch (error) {
      console.log(error);
      if (typeof error.message === "string") {
        enqueueSnackbar({ message: error.message, variant: "error" });
      }
    }
  } // End of onSave

  function haveFile() {
    if (props.selection === null) {
      return false;
    }
    // If we have more than 1 selection, nothing to do
    if (props.selection.selection.size !== 1) {
      return false;
    }
    return true;
  }

  function getFileName() {
    if (!haveFile()) {
      return "none";
    }
    const fileName = props.selection.selection.values().next().value;
    return `gs://${props.selection.bucket}/${fileName}`;
  }

  /**
   * render
   * @returns
   */
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        style={{
          marginTop: "8px",
          display: "flex",
          padding: "0 1rem",
          gap: "0.5rem",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => changeTextMode("json-tree")}
        >
          JSON-tree
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => changeTextMode("json")}
        >
          JSON
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => changeTextMode("text")}
        >
          Text
        </Button>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginTop: "8px",
          padding: "0 1rem",
        }}
      >
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={(w, h) => {
            console.log(`${w} x ${h}`);
          }}
        />
        {textMode === "json-tree" && (
          <EditorJSONTree
            ref={jsonTreeRef}
            text={text}
            json={json}
            onChange={onChange}
          />
        )}
        {textMode === "json" && (
          <EditorJSON
            ref={jsonRef}
            text={text}
            json={json}
            onChange={onChange}
          />
        )}
        {textMode === "text" && (
          <EditorText ref={textRef} text={text} onChange={onChange} />
        )}
      </Box>
      <Paper variant="outlined" style={{ margin: "4px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box style={{ marginLeft: "4px", flexGrow: 1 }}>
            File: {getFileName()}
          </Box>
          <Button
            variant="contained"
            color="primary"
            disabled={!haveFile()}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
/*
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AceEditor from "react-ace";
import ReactResizeDetector from 'react-resize-detector'
import { useEffect, useState } from 'react';
function App() {

  const [width, setWidth] = useState(400)
  const [height, setHeight] = useState(10)
  function onResize(w, h) {
    setWidth(w);
    setHeight(h)
  }
  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Button>Hello Button</Button>
      <Box style={{ backgroundColor: "grey", flexGrow: 1 }}>
        <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        <AceEditor width={width} height={height}></AceEditor>
      </Box>
      <Button>Bottom Button</Button>
    </Box>
  );
}

export default App;
*/

FileEditor.propTypes = {
  selection: PropTypes.object,
};

export default FileEditor;
