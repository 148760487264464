import React, { forwardRef } from "react";

import { JsonEditor as Editor } from "jsoneditor-react";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

const EditorJSONTree = forwardRef(({ text, json, onChange, ...props }, ref) => {
  return (
    <Editor
      {...props}
      ref={ref}
      ace={ace}
      value={json}
      onChange={onChange}
      theme="ace/theme/github"
      mode="tree"
      htmlElementProps={{ className: "editorWrapper" }}
    />
  );
});

export default EditorJSONTree;
